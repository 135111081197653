define("discourse/plugins/discourse-assign/discourse/components/user-menu/assigns-list", ["exports", "@ember/object", "@ember/object/computed", "discourse/components/user-menu/notifications-list", "I18n", "discourse/plugins/discourse-assign/discourse/components/user-menu/assigns-list-empty-state"], function (_exports, _object, _computed, _notificationsList, _I18n, _assignsListEmptyState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  class UserMenuAssignNotificationsList extends _notificationsList.default {
    get dismissTypes() {
      return this.filterByTypes;
    }
    get showDismiss() {
      return this._unreadAssignedNotificationsCount > 0;
    }
    get dismissTitle() {
      return _I18n.default.t("user.dismiss_assigned_tooltip");
    }
    get showAllHref() {
      return `${this.currentUser.path}/activity/assigned`;
    }
    get showAllTitle() {
      return _I18n.default.t("user_menu.view_all_assigned");
    }
    get itemsCacheKey() {
      return "user-menu-assigns-tab";
    }
    get emptyStateComponent() {
      return _assignsListEmptyState.default;
    }
    get alwaysRenderDismissConfirmation() {
      return true;
    }
    get _unreadAssignedNotificationsCount() {
      const key = `grouped_unread_notifications.${this.site.notification_types.assigned}`;
      // we're retrieving the value with get() so that Ember tracks the property
      // and re-renders the UI when it changes.
      // we can stop using `get()` when the User model is refactored into native
      // class with @tracked properties.
      return this.currentUser.get(key) || 0;
    }
    get dismissConfirmationText() {
      return _I18n.default.t("notifications.dismiss_confirmation.body.assigns", {
        count: this._unreadAssignedNotificationsCount
      });
    }
    async fetchItems() {
      return new SortedItems(await super.fetchItems()).sortedItems;
    }
  }
  _exports.default = UserMenuAssignNotificationsList;
  let SortedItems = (_dec = (0, _computed.sort)("items", "itemsSorting"), (_class = class SortedItems {
    constructor(items) {
      _defineProperty(this, "itemsSorting", ["notification.read", "notification.data.message:desc", "notification.created_at:desc"]);
      _initializerDefineProperty(this, "sortedItems", _descriptor, this);
      (0, _object.set)(this, "items", items);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sortedItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});