define("discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-assign/discourse/components/assign-actions-dropdown", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _assignActionsDropdown, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AssignedTopicListColumn = _exports.default = (_class = (_class2 = class AssignedTopicListColumn extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    async unassign(targetId1) {
      let targetType1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Topic";
      await this.taskActions.unassign(targetId1, targetType1);
      this.router.refresh();
    }
    reassign(topic1) {
      this.taskActions.showAssignModal(topic1, {
        onSuccess: () => this.router.refresh()
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @topic.assigned_to_user}}
        <AssignActionsDropdown
          @topic={{@topic}}
          @assignee={{@topic.assigned_to_user.username}}
          @unassign={{this.unassign}}
          @reassign={{this.reassign}}
        />
      {{else if @topic.assigned_to_group}}
        <AssignActionsDropdown
          @topic={{@topic}}
          @assignee={{@topic.assigned_to_group.name}}
          @group={{true}}
          @unassign={{this.unassign}}
          @reassign={{this.reassign}}
        />
      {{else}}
        <AssignActionsDropdown @topic={{@topic}} @unassign={{this.unassign}} />
      {{/if}}
    
  */
  {
    "id": "CgiPEHvE",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"assigned_to_user\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\",\"@assignee\",\"@unassign\",\"@reassign\"],[[30,1],[30,1,[\"assigned_to_user\",\"username\"]],[30,0,[\"unassign\"]],[30,0,[\"reassign\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,1,[\"assigned_to_group\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\",\"@assignee\",\"@group\",\"@unassign\",\"@reassign\"],[[30,1],[30,1,[\"assigned_to_group\",\"name\"]],true,[30,0,[\"unassign\"]],[30,0,[\"reassign\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,0],null,[[\"@topic\",\"@unassign\"],[[30,1],[30,0,[\"unassign\"]]]],null],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"]],[\"@topic\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column.js",
    "scope": () => [_assignActionsDropdown.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "unassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reassign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reassign"), _class.prototype)), _class);
});