define("discourse/plugins/discourse-assign/discourse/controllers/user-activity-assigned", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/template", "discourse/controllers/user-topics-list", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _template, _userTopicsList, _environment, _debounce, _getUrl, _iconLibrary, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserActivityAssigned = _exports.default = (_dec = (0, _decorators.default)("model.topics.length", "search"), (_class = class UserActivityAssigned extends _userTopicsList.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "taskActions", _descriptor, this);
      _initializerDefineProperty(this, "user", _descriptor2, this);
      _defineProperty(this, "queryParams", ["order", "ascending", "search"]);
      _defineProperty(this, "order", "");
      _defineProperty(this, "ascending", false);
      _defineProperty(this, "search", "");
    }
    doesntHaveAssignments(topicsLength, search) {
      return !search && !topicsLength;
    }
    emptyStateBody() {
      return (0, _template.htmlSafe)(_I18n.default.t("user.no_assignments_body", {
        preferencesUrl: (0, _getUrl.default)("/my/preferences/notifications"),
        icon: (0, _iconLibrary.iconHTML)("user-plus")
      }));
    }
    _setSearchTerm(searchTerm) {
      this.set("search", searchTerm);
      this.refreshModel();
    }
    refreshModel() {
      this.set("loading", true);
      this.store.findFiltered("topicList", {
        filter: this.model.filter,
        params: {
          order: this.order,
          ascending: this.ascending,
          search: this.search
        }
      }).then(result => this.set("model", result)).finally(() => {
        this.set("loading", false);
      });
    }
    changeSort(sortBy) {
      if (sortBy === this.order) {
        this.toggleProperty("ascending");
        this.refreshModel();
      } else {
        this.setProperties({
          order: sortBy,
          ascending: false
        });
        this.refreshModel();
      }
    }
    onChangeFilter(value) {
      (0, _debounce.default)(this, this._setSearchTerm, value, _environment.INPUT_DELAY * 2);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskActions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_controller.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doesntHaveAssignments", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "doesntHaveAssignments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emptyStateBody", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "emptyStateBody"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilter"), _class.prototype)), _class));
});